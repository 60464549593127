/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable test-selectors/onChange */
import React from 'react';
import type {
	CSSObjectWithLabel,
	Props,
	ValueContainerProps,
} from 'react-select';
import Select, { components as ComponentRS, MenuListProps } from 'react-select';

import {
	Badge,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Text,
} from '@ctlyst.id/internal-ui';
import voilaTheme from '@ctlyst.id/voila-theme';
import { useVirtualizer } from '@tanstack/react-virtual';
import { truncateString } from '@utils';

type OptionValue = {
	label: string;
	value: string | number | boolean;
	isDisabled?: boolean;
};

export type OptionGroup<Option> = {
	label: string;
	value: string | number | boolean;
	selectAllCheckbox?: boolean;
} & Partial<Option>;

export type OptionMultiGroup<Option = unknown> = {
	label: string;
	value: string | number | boolean;
	group: string;
	selectAllCheckbox?: boolean;
} & Partial<Option>;

export type OptionsGroup<Option> = {
	readonly label: string;
	readonly options: readonly OptionMultiGroup<Option>[];
}[];

export const CHECKBOX_STATE = {
	UNCHECKED: 0,
	INDETERMINATE: 1,
	CHECKED: 2,
} as const;

export const getSelectAllCheckboxState = (
	totalSelected: number,
	totalOption: number
) => {
	if (totalSelected < 0 || totalOption < 0) {
		throw new Error('value less than 0');
	}
	if (totalSelected > totalOption) {
		throw new Error("totalSelected couldn't be more than totalOption");
	}
	if (totalSelected === 0) {
		return CHECKBOX_STATE.UNCHECKED;
	}
	if (totalSelected < totalOption) {
		return CHECKBOX_STATE.INDETERMINATE;
	}
	if (totalSelected === totalOption) {
		return CHECKBOX_STATE.CHECKED;
	}
	return CHECKBOX_STATE.UNCHECKED;
};

const MenuList = <Option,>(props: MenuListProps<Option>) => {
	const { children } = props;
	const childrenArr = Array.isArray(children) ? children : [children];
	const itemCount = childrenArr.length;

	const parentRef = React.useRef(null);

	const rowVirtualizer = useVirtualizer({
		count: itemCount,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 39,
		overscan: 5,
	});

	return (
		<div
			ref={parentRef}
			style={{
				height: `300px`,
				width: `100%`,
				overflow: 'auto',
			}}
		>
			<div
				style={{
					height: `${rowVirtualizer.getTotalSize()}px`,
					width: '100%',
					position: 'relative',
				}}
			>
				{rowVirtualizer.getVirtualItems().map(virtualRow => (
					<div
						key={virtualRow.index}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: `${virtualRow.size}px`,
							transform: `translateY(${virtualRow.start}px)`,
						}}
					>
						{childrenArr[virtualRow.index]}
					</div>
				))}
			</div>
		</div>
	);
};

const CustomMultiValue = (props: any) => {
	return (
		<ComponentRS.MultiValue {...props}>
			<span>{truncateString(props.data.label, 50)}</span>
		</ComponentRS.MultiValue>
	);
};

const ValueContainer = <Option,>({
	children,
	...props
}: ValueContainerProps<Option>) => {
	const [values, input] = children as any;
	const viewValue = React.Children.toArray(values).slice(0, 2);
	const value = props.getValue();
	return (
		<Flex alignItems="center">
			<ComponentRS.ValueContainer {...props}>
				{viewValue}
				{value.length > 2 && (
					<Badge variant="neutral-light">{value.length - 2}+</Badge>
				)}
				{input}
			</ComponentRS.ValueContainer>
		</Flex>
	);
};

export interface SelectWithCheckboxBaseProps<
	T = OptionGroup<any> | OptionMultiGroup<any>,
	IsMulti extends boolean = boolean
> extends Props<T, IsMulti> {
	isRequired?: boolean;
	isError?: boolean;
	errorMsg?: string;
	label?: string;
	virtualized?: boolean;
}
const SelectWithCheckboxBase = <T, IsMulti extends boolean>(
	props: SelectWithCheckboxBaseProps<T, IsMulti>
) => {
	const {
		onChange,
		value,
		options,
		isDisabled,
		components,
		label,
		isRequired,
		isError,
		errorMsg,
		virtualized,
		isMulti,
		...rest
	} = props;
	return (
		<FormControl>
			{label && (
				<FormLabel>
					{isRequired && (
						<Text mr={1} as="span" color={'danger.500'}>
							*
						</Text>
					)}
					<Text as="span" textStyle="text.sm" color={'black.hight'}>
						{label}
					</Text>
				</FormLabel>
			)}

			<Select
				{...rest}
				value={value}
				isMulti={isMulti}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				onChange={onChange}
				options={options}
				isDisabled={isDisabled}
				components={{
					...(virtualized ? { MenuList } : {}),
					MultiValue: CustomMultiValue,
					ValueContainer,
					NoOptionsMessage: noOptionProps => {
						if (noOptionProps.options.length === 0) {
							return (
								<Center fontSize="xs" py="2" color={'black.medium'}>
									Pilihan tidak tersedia
								</Center>
							);
						}
						return (
							<Center fontSize="xs" py="2" color={'black.medium'}>
								Keyword tidak ditemukan
							</Center>
						);
					},
					...components,
				}}
				styles={{
					menuList: base => ({
						...base,
						paddingTop: 0,
					}),
					placeholder: defaultStyles => {
						return {
							...defaultStyles,
							fontSize: 12,
						};
					},
					valueContainer: (styles: CSSObjectWithLabel) => {
						return {
							...styles,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							padding: '8px 8px',
							maxWidth: 600,
						};
					},
					input: (base: CSSObjectWithLabel) => {
						return {
							...base,
							margin: 0,
							padding: 0,
							lineHeight: '18px',
						};
					},
					multiValue: (styles, multiValueProps) => {
						const valueData = multiValueProps?.data as OptionValue;
						const isDisabledValue = !!valueData?.isDisabled;
						return {
							...styles,
							backgroundColor: isDisabledValue ? '#E0E0E0' : '#F5F5F5',
							height: '18px',
							fontSize: '10px',
							color: '#999',
							borderRadius: '4px',
						};
					},
					multiValueLabel: styles => {
						return {
							...styles,
							fontSize: '10px',
							color: '#999',
							fontWeight: '600',
							padding: '1px',
						};
					},
					multiValueRemove: styles => {
						return {
							...styles,
							fontWeight: '100',
						};
					},
					groupHeading: styles => {
						return {
							...styles,
							color: voilaTheme.colors.black.high,
							fontSize: '12px',
							fontWeight: 600,
							margin: 0,
						};
					},
					group: styles => {
						return {
							...styles,
							paddingTop: 0,
						};
					},
					option: (base, { isFocused, isSelected }) => ({
						...base,
						backgroundColor:
							isSelected || isFocused
								? voilaTheme.colors.primary[50]
								: 'tranparent',
						'&:active': {
							backgroundColor: isFocused
								? voilaTheme.colors.primary[50]
								: 'transparent',
						},
					}),
					control: base => {
						const _style: CSSObjectWithLabel = {
							...base,
							flexWrap: 'nowrap',
							borderColor: voilaTheme.colors.neutral[500],
							boxShadow: 'none',
							'&:hover': {
								borderColor: voilaTheme.colors.neutral[500],
							},
						};
						if (isError) {
							return {
								..._style,
								borderColor: voilaTheme.colors.danger[500],
							};
						}
						return _style;
					},
				}}
				{...rest}
			/>
			{isError && errorMsg && (
				<Text mt={1} fontWeight="semibold" fontSize="10px" color="danger.400">
					{errorMsg}
				</Text>
			)}
		</FormControl>
	);
};

export default SelectWithCheckboxBase;
